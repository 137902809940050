<!-- eslint-disable quotes -->
<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo mb-3">
          <!-- <vuexy-logo /> -->
          <!-- <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1006.000000pt"
            height="830.000000pt"
            viewBox="0 0 1006.000000 830.000000"
            preserveAspectRatio="xMidYMid meet"
            style="width: 150px; height: 150px;"
          >
            <g
              transform="translate(0.000000,830.000000) scale(0.100000,-0.100000)"
              stroke="none"
            >
              <path
                d="M4908 8289 c-328 -25 -693 -152 -968 -337 -211 -141 -368 -290 -513
            -484 -69 -93 -74 -98 -110 -98 -47 0 -146 -33 -199 -66 -204 -129 -265 -420
            -129 -617 161 -234 481 -264 681 -64 131 131 164 334 81 502 l-30 60 27 40
            c14 22 87 101 162 176 245 246 503 386 850 460 91 20 134 23 320 23 186 0 229
            -3 320 -23 345 -74 605 -215 851 -460 245 -246 386 -506 460 -851 31 -141 34
            -475 6 -615 -83 -420 -251 -705 -1192 -2030 -160 -225 -324 -458 -365 -517
            -41 -60 -77 -108 -80 -108 -4 0 -40 48 -81 108 -40 59 -188 269 -329 467 -363
            510 -519 733 -659 944 -143 214 -311 480 -311 492 0 5 164 9 365 9 324 0 368
            2 400 17 26 12 127 123 347 380 170 200 318 373 328 385 17 21 20 18 343 -360
            179 -209 339 -389 354 -399 20 -13 47 -18 98 -18 63 0 76 3 109 29 77 58 98
            178 44 251 -80 109 -797 935 -831 958 -49 32 -128 37 -184 10 -24 -11 -146
            -147 -405 -450 l-371 -433 -489 0 c-532 0 -530 0 -591 -58 -33 -31 -59 -111
            -50 -155 8 -45 130 -286 220 -436 171 -285 409 -635 903 -1331 174 -245 353
            -500 399 -567 45 -68 101 -139 124 -159 147 -128 377 -130 529 -4 21 17 81 95
            135 173 54 78 235 336 403 572 934 1313 1131 1654 1224 2115 137 678 -75 1382
            -563 1871 -350 349 -813 561 -1311 599 -148 11 -166 11 -322 -1z m-1472 -1194
            c80 -40 117 -118 100 -210 -8 -41 -55 -99 -100 -122 -64 -33 -163 -15 -212 39
            -92 101 -63 241 61 299 54 25 92 24 151 -6z" />
              <path d="M4870 5455 l0 -115 115 0 115 0 0 115 0 115 -115 0 -115 0 0 -115z" />
              <path d="M5220 5455 l0 -115 110 0 110 0 0 115 0 115 -110 0 -110 0 0 -115z" />
              <path d="M4870 5110 l0 -110 115 0 115 0 0 110 0 110 -115 0 -115 0 0 -110z" />
              <path d="M5220 5110 l0 -110 110 0 110 0 0 110 0 110 -110 0 -110 0 0 -110z" />
              <path d="M0 680 l0 -650 110 0 110 0 0 195 0 195 188 0 187 -1 115 -191 c63
            -104 119 -192 125 -195 6 -2 62 -2 123 -1 l112 3 -140 210 c-77 116 -140 212
            -140 215 0 3 25 18 55 34 70 36 129 101 168 184 27 59 31 79 35 178 4 94 1
            123 -17 181 -27 88 -77 154 -154 204 -118 76 -199 89 -579 89 l-298 0 0 -650z
            m598 450 c86 -11 146 -36 185 -76 51 -51 62 -82 62 -174 0 -99 -20 -148 -82
            -199 -63 -52 -112 -61 -340 -61 l-203 0 0 253 c0 140 3 257 7 260 10 10 287 8
            371 -3z" />
              <path d="M1720 680 l0 -650 475 0 475 0 0 95 0 95 -370 0 -370 0 2 183 3 182
            318 3 317 2 0 95 0 95 -320 0 -320 0 2 178 3 177 353 3 352 2 0 95 0 95 -460
            0 -460 0 0 -650z" />
              <path d="M3300 680 l0 -650 105 0 105 0 1 483 1 482 206 -417 205 -418 69 0
            69 0 207 418 207 417 3 -482 2 -483 100 0 100 0 0 650 0 650 -128 0 -128 0
            -214 -430 c-117 -236 -215 -430 -217 -430 -1 0 -100 194 -218 430 l-215 430
            -130 0 -130 0 0 -650z" />
              <path d="M5774 1273 c-308 -695 -544 -1233 -544 -1238 0 -3 50 -4 111 -3 l112
            3 213 505 c117 278 218 516 225 530 l11 25 13 -25 c7 -14 108 -250 225 -525
            116 -275 215 -504 219 -508 4 -4 57 -6 118 -5 l111 3 -282 645 -282 645 -113
            3 -112 3 -25 -58z" />
              <path d="M7130 681 l0 -651 110 0 110 0 0 194 0 194 218 5 c231 5 277 13 377
            62 72 35 157 128 184 200 65 172 40 376 -59 489 -48 55 -108 92 -196 123 l-79
            27 -332 4 -333 4 0 -651z m578 449 c135 -18 210 -71 239 -169 31 -106 1 -218
            -74 -280 -62 -52 -113 -61 -330 -61 l-193 0 0 253 c0 140 3 257 7 260 10 10
            267 8 351 -3z" />
              <path d="M9875 1206 c-73 -32 -103 -110 -70 -179 40 -86 149 -102 212 -33 28
            30 33 43 33 83 0 60 -29 109 -75 129 -42 17 -61 17 -100 0z" />
              <path d="M9025 870 c-170 -56 -275 -217 -275 -420 0 -199 101 -358 268 -421
            47 -17 73 -20 147 -17 95 5 147 23 210 76 l30 25 3 -41 3 -42 105 0 104 0 0
            420 0 420 -105 0 -105 0 0 -45 0 -45 -47 34 c-70 50 -119 67 -203 72 -57 3
            -89 -1 -135 -16z m240 -181 c51 -14 130 -93 144 -146 20 -71 14 -164 -15 -221
            -97 -196 -382 -158 -434 58 -48 200 112 362 305 309z" />
              <path d="M9820 450 l0 -420 100 0 100 0 0 420 0 420 -100 0 -100 0 0 -420z" />
              <path d="M5893 654 c-4 -10 -64 -154 -134 -319 l-127 -300 140 -3 c76 -1 199
            -1 272 0 l133 3 -134 314 c-73 173 -135 317 -138 319 -2 3 -8 -4 -12 -14z" />
              <path d="M8388 224 c-53 -28 -72 -98 -43 -154 22 -42 53 -60 106 -60 56 0 93
            25 109 76 35 104 -75 191 -172 138z" />
            </g>
          </svg> -->
          <img
            src="@/assets/images/logo/logo.png"
            style="width: 15rem; height: auto"
            alt=""
          />
        </b-link>
        <p class="mb-2" style="margin-top: -30px; text-align: center">
          Focus your time on properties that matter
        </p>

        <b-card-title class="mb-0 text-center">
          Welcome to REMAP.ai
        </b-card-title>
        <b-card-text class="mb-2">
          Please use your email to sign into your account
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  @keyup="emailLowerCase()"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              id="signInBtn"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              v-show="!hideSignInBtn"
            >
              Sign in
            </b-button>

            <b-button
              ref="loadableButton"
              variant="primary"
              id="button-with-loading"
              class="vs-con-loading__container"
              type="relief"
              v-show="hideSignInBtn"
              disabled
            ></b-button>

            <b-button variant="primary" type="submit" block :href="'/register'">
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span
            >New on our platform? Check out our
            <b-link to="pages/pricing">Monthly Plans</b-link> to gain access
          </span>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line semi, quotes
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  // eslint-disable-next-line quotes
} from "bootstrap-vue";
// eslint-disable-next-line import/no-extraneous-dependencies, semi, quotes
import VuexyLogo from "@core/layouts/components/Logo.vue";
// eslint-disable-next-line quotes, import/no-extraneous-dependencies, semi
import useJwt from "@/auth/jwt/useJwt";
// eslint-disable-next-line import/no-extraneous-dependencies, quotes, semi
import { required, email } from "@validations";
// eslint-disable-next-line import/no-extraneous-dependencies, quotes, semi
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// eslint-disable-next-line import/no-extraneous-dependencies, quotes, no-unused-vars, semi
import store from "@/store/index";
// eslint-disable-next-line import/no-extraneous-dependencies, quotes, semi
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

// eslint-disable-next-line import/no-extraneous-dependencies, quotes, semi
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Vue from "vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
      backgroundLoading: "primary",
      colorLoading: "#fff",
      hideSignInBtn: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.hideSignInBtn = true;
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: this.$refs.loadableButton,
            scale: 0.45,
          });
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((resp) => {
              if (resp.data.success) {
                const userData = resp.data.response;
                useJwt.setToken(userData.accessToken);
                useJwt.setRefreshToken(userData.refreshToken);
                let ability = userData.ability;

                this.$ability.update(ability);

                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem("isAdminSwitch", "false");
                localStorage.setItem("checkAuth", "true");

                this.$router.replace(
                  getHomeRouteForLoggedInUser(userData.role)
                );
              } else {
                this.hideSignInBtn = false;
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      "User not found , please check your email and password",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    emailLowerCase() {
      this.userEmail = this.userEmail.toString().toLowerCase();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.fill-row {
  flex: 0 0 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#button-with-loading {
  width: 100% !important;
  height: 38px !important;
  margin-bottom: 10px;
}

#signInBtn {
  margin-bottom: 10px !important;
}

.vs-loading-background-primary {
  background-color: #1f5a94 !important;
}
</style>
